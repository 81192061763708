/* eslint-env node */
/*
 * This file is automatically compiled by Webpack, along with any other files
 * present in this directory. You're encouraged to place your actual application logic in
 * a relevant structure within app/javascript and only use these pack files to reference
 * that code so it'll be compiled.
 */
// Polyfills
import 'dom4';
/* global $:true */ // eslint-disable-line
import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import {definitionsFromContext} from '@hotwired/stimulus-webpack-helpers';
import {Application} from '@hotwired/stimulus';
import $ from 'jquery';
import Foundation from 'foundation-sites'; // eslint-disable-line

Rails.start();
Turbolinks.start();

// Require syntax is required, and this must come after Turbolinks.start()
require('@client-side-validations/client-side-validations');

// Foundation
$(document).on('turbolinks:load', () => {
    $(document).foundation();
});

// React
const componentRequireContext = require.context('components', true);
const ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(componentRequireContext);// Support component names relative to this directory:

// Stimulus
const application = Application.start();
application.debug = process.env.RAILS_ENV === 'development';
window.Stimulus = application;

const context = require.context('controllers', true, /\.js$/);
application.load(definitionsFromContext(context));

document.addEventListener('turbolinks:before-cache', () => {
    application.controllers.forEach(controller => {
        if (typeof controller.teardown === 'function') {
            controller.teardown();
        }
    });

    const offcanvas = $('.off-canvas');

    if (offcanvas.hasClass('is-open')) {
        offcanvas.foundation('close');
    }
});

export {application};