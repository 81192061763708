import {Controller} from '@hotwired/stimulus';
import Rellax from 'rellax';
import $ from 'jquery';

export default class extends Controller {
    connect() {
        this.clearCache();
        this.startParralax();
    }

    startParralax = () => {
        // $(window).on('turbolinks:load', () => {
        $(this.element).addClass('visible');
        window.rellax = new Rellax(this.element, {
            center: false,
            round: true,
            min: 0
        });
        // });
    };

    teardown() {
        this.clearCache();
    }

    clearCache = () => {
        $(this.element).removeClass('visible');

        if (window.rellax) {
            // End Rellax and reset parallax elements to their original positions
            window.rellax.destroy();
        }
        // const rellax = new Rellax(this.element);
    };
}