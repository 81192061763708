import {Controller} from '@hotwired/stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
    static targets = ['form', 'otherSelect', 'otherTextField'];

    continue = () => {
        Rails.fire(this.formTarget, 'submit');
    };

    toggle = event => {
        const textFieldTriggeringOptionId = this.otherTextFieldTargets
            .find(htmlNode => htmlNode.id === `${event.target.id}_other`).getAttribute('data-other-id');
        const allOptions = this.otherSelectTargets.find(htmlNode => htmlNode === event.target).children;
        const otherTextField = this.otherTextFieldTargets.find(htmlNode => htmlNode.id === `${event.target.id}_other`);
        const selectedOption = Array.from(allOptions).find(element => element.selected);

        if (textFieldTriggeringOptionId === selectedOption.value) {
            otherTextField.disabled = false;
            otherTextField.className = 'other-text-field';
        }
        else {
            otherTextField.disabled = true;
            otherTextField.className = 'other-text-field-hidden';
        }
    };
}