import {Controller} from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
    static targets = ['shareStoryCheckBox', 'dontShareStoryCheckBox'];

    toggleShareStoryCheckBoxState = () => {
        const shareStoryCheckBox = $(this.shareStoryCheckBoxTarget);
        const dontShareStoryCheckbox = $(this.dontShareStoryCheckBoxTarget);

        if (shareStoryCheckBox.prop('checked')) {
            dontShareStoryCheckbox.prop('checked', false);
        }
        else if (dontShareStoryCheckbox.prop('checked')) {
            shareStoryCheckBox.prop('checked', false);
        }
    };

    toggleDontShareStoryCheckBoxState = () => {
        const shareStoryCheckBox = $(this.shareStoryCheckBoxTarget);
        const dontShareStoryCheckbox = $(this.dontShareStoryCheckBoxTarget);

        if (dontShareStoryCheckbox.prop('checked')) {
            shareStoryCheckBox.prop('checked', false);
        }
    };
}