import {Controller} from '@hotwired/stimulus';
import 'slick-carousel';
import $ from 'jquery';

export default class extends Controller {
    connect = () => {
        this.clearCache();
        this.startGlide();
    };

    teardown() {
        this.clearCache();
    }

    startGlide = () => { // eslint-disable-line class-methods-use-this
        window.carousel = $('.glide').slick({
            slidesToShow: 3,
            mobileFirst: true,
            autoplay: true,
            autoplaySpeed: 8000,
            responsive: [
                {
                    breakpoint: 1000,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }
                },
                {
                    breakpoint: 700,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }
                },
                {
                    breakpoint: 1,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                /*
                 * You can unslick at a given breakpoint now by adding:
                 * settings: "unslick"
                 * instead of a settings object
                 */
            ]
        });
        $(window).on('resize', () => {
            $('.glide').slick('resize');
        });
    };

    clearCache = () => { // eslint-disable-line class-methods-use-this
        if (window.carousel) {
            window.carousel.slick('unslick');
            window.carousel = null;
        }
    };
}