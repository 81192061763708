import {Controller} from '@hotwired/stimulus';
import MicroModal from 'micromodal';
import ReactRailsUJS from 'react_ujs';
import $ from 'jquery';

export default class extends Controller {
    static targets = ['container'];

    connect() {
        this.clearCache();
        $(document).on('click.modal', '[data-modal]', e => this.showModal(e));
    }

    showModal(e) {
        e.preventDefault();
        const modalSelector = '.modal';
        const $containerTarget = $(this.containerTarget);

        MicroModal.init();
        const location =
      $(e.currentTarget).attr('href') || $(e.currentTarget).data('url');

        // Load modal dialog from server
        $.get(location, data => {
            const el = $containerTarget.html(data).find(modalSelector);
            MicroModal.show(el.attr('id'));
            ReactRailsUJS.mountComponents(modalSelector);
        });

        return false;
    }

    teardown() {
        this.clearCache();
    }

    clearCache() {
        $(document).off('click.modal');
        const $containerTarget = $(this.containerTarget);
        $containerTarget.html();
    }
}